import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useJSONState, useLogout, useTranslate } from '../hooks';
import "../assets/style/Shipments.css"
import { TextField, Button, ConfirmDialog, Grid, Container, Group, Centralizer, Switch, Select, DataTable } from '../components';
import { ShipmentType } from '../types/Shipments';
import { ConfirmDialogProps } from '../components/Utils/ConfirmDialog';
import Shipment from './Shipment';
import { format } from 'date-fns';
import TourStepper from "../components/Surfaces/TourStepper"
import { toast } from 'sonner';
import { deepCopyOf, dialog, sleep } from '../utils';
import { FaCircleInfo } from "react-icons/fa6";

type statusSearch = {
  [key: string]: string
}

export default function Shipments() {
  // Hooks
  const api = useAxiosPrivate();
  const logout = useLogout();
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslate();
  const [ hideTour, setHideTour ] = useState(localStorage.hideTour)
  const [ statuses, setStatuses ] = useState<statusSearch[]>([])
  const [ triggerReload, setTriggerReload ] = useState(false);

  // States
  const [shipments, setProp, setShipments] = useJSONState<ShipmentType[]>();
  const [newShipmentForm, setNewShipmentForm] = useState(false)
  const [confirmDialog, setConfirmDialog] = useState<ConfirmDialogProps>();
  const [showInterval, setShowInterval] = useState(false);
  const [searchFields, setSearchField, setSearchFields] = useJSONState({
    trackingNo: undefined,
    orderNumber: undefined,
    receiver: undefined,
    dispDate: undefined,
    carrier: undefined,
    status: undefined,
    shipmentId: undefined,
    dispDateFrom: undefined,
    dispDateTo: undefined,
  })

  // Defaults
  const dialogDefault = {
    title: t("NoShipmentFound"),
    message: t("VerifySearchConditions"),
    noCancel: true,
    type: "info",
    onOk: () => { setConfirmDialog(undefined) }
  } as ConfirmDialogProps

  const searchShipment = async (e: any, initial:boolean=false) => {
    if(e) e.preventDefault()
    let searchData = {} as any;

    if(initial){
      let sStorage = sessionStorage.getItem("searchFields");
      searchData = JSON.parse(sStorage ?? "{}")
      if(Object.keys(searchData).length == 0) return
      setSearchFields({...searchData})
    } 
    else sessionStorage.setItem("searchFields", JSON.stringify(searchFields ?? {}))

    setTriggerReload((v) => !v)
  }

  const fetchShipments = async ({page, snapshot, pageSize}: {
    page:number,
    snapshot: string,
    pageSize: number,
  }) => {
    const response = await api.post({
      endpoint: "/ShipmentsList",
      data: {
        searchFields, page, snapshot, pageSize
      },
      mount: null
    })

    let data = response?.data?.data
    console.log({data})
    if(!data){
      toast.info(t("NoShipmentFound") + ".\n" + t("VerifySearchConditions"))
      return Promise.resolve()
    }

    const pages = response.data.pages
    snapshot = response.data.snapshot

    data = data.reduce((prev:any, cur:ShipmentType) => {
      const rec = cur.addresses.receiver
      const foo = {
        orderNumber: cur.orderNumber,
        receiver: `${rec.name}\n${rec.street1}\n${rec.countryCode}-${rec.zipCode} ${rec.city}`,
        dispDate: cur.dispDate,
        carrier: cur.carrier,
        status: <div className={getStatusElementClass(cur.status)}><p className='m-auto'>{t(`ta_statuses.${cur.status}`)}</p></div>,
        "": !cur.trackingLink ? "" :<Button className="schipt-btn secondary" onClick={() => {
            cur.trackingLink && window.open(cur.trackingLink, 'targetWindow',
              `scrollbars=yes,
                resizable=yes,
                width=500,
                left=500,
                height=500`)
          }} _type="button" variant='submit'>Tracking</Button>,
          noteInternal: cur.noteInternal !== '' ? <FaCircleInfo/> : null,
        shipmentId: cur.shipmentId        
      }
      prev.push(foo)
      return prev
    }, [])

    return Promise.resolve({data:data as ShipmentType[], snapshot, pages})
  }


  const endOfDay = async () => {
    const shipmentIds = shipments.reduce<string[]>((prev, cur) => {
      if(cur.checked && cur.shipmentId) prev.push(cur.shipmentId)
      return prev
    }, []);

    const res = await dialog.open({
      book: {name: t("bookBooking"), type:"switch"},
      waybills: {name: t("eod.waybills"), type:"switch"},
      labels: {name: t("eod.labels"), type:"switch"},
    }, {title: t("eod.header").replace("{1}", shipmentIds.length.toString())})

    if(!res) return;
    
    const documents = Object.keys(res).reduce((prev: ('book' | 'waybills' | 'labels')[], cur: string) => {
      const r = cur as 'book' | 'waybills' | 'labels'
      if (res[r]) {
        prev.push(r);
      }
      return prev;
    }, []);

    if(!documents) return

    try{
      api.post({
        endpoint: "shipments/print",
        mount: {
          loadingText: t("printing"),
          onLoadText: t("printed")
        },
        data: {shipmentIds, documents}
      })
    }catch(e){
      console.error(e)
    }    
  }

  useEffect(() => {
    setStatuses([
      {value:"10", name: t("open")},
      {value:"20", name: t("inProgress")},
      {value:"[12]0", name: t("openAndInProgress")},
      {value:"80", name: t("finished")},
      {value:"90", name: t("cancelled")},
    ])

    if ((location?.state as any)?.dialog !== undefined) {
      setConfirmDialog({ ...dialogDefault, ...(location?.state as any)?.dialog })
      window.history.replaceState({}, document.title)
    }
    searchShipment(null, true)
  }, [])

  const props = (key: string) => {
    return {
      value: searchFields[key],
      onChange: (e: any) => setSearchField(e.target.value, key),
      onBlur: (e: any) => {
        if (e.target.value.trim().length === 0)
          setSearchField(undefined, key)

        if (key.toLocaleLowerCase().indexOf("date") > 0) {
          if (key === "dispDate") {
            let obj = { ...searchFields }
            delete obj.dispDateFrom
            delete obj.dispDateTo
            setSearchFields(obj)
          } else setSearchField(undefined, "dispDate")
        }
      } //if empty space, set undefined
    }
  }

  const getStatusElementClass = (status: number) => {
    let c = 'bg-gray-200 text-gray-600'
    if(status === 10) c = 'bg-gray-200 text-gray-600'
    else if (status === 20) c = 'bg-yellow-200 text-yellow-600'
    else if (status === 50) c = 'bg-blue-200 text-blue-600'
    else if (status === 70) c = 'bg-gray-400 text-gray-700'    
    else if (status === 80) c = 'bg-green-200 text-green-600'
    else if (status === 90) c = 'bg-orange-200 text-orange-600'
    else if (status === -99) c = 'bg-red-300 text-red-600'

    return `status flex content-center py-1 px-2 rounded font-medium text-center ${c}`    
  }

  return (
    <>
      {!newShipmentForm ? <>
        <Centralizer>
          <Container style={{ height: "70vh", maxHeight: "70vh", width: "clamp(0%, 100%, 1400px)" }}>
            <Group label={t("searchCriteria")}>
              <form onSubmit={(e) => searchShipment(e)}>
                <Grid>
                  <TextField {...props("trackingNo")}
                    title={t("enterConsOrPackageNo")}>{t("trackingNumber")}</TextField>
                  <TextField {...props("orderNumber")} id="shipment-orderno">{t("orderNumber")}</TextField>
                  <TextField {...props("receiver")}>{t("receiver")}</TextField>
                  <TextField {...props("carrier")} id="shipment-carrier">{t("carrier")}</TextField>
                  <Select {...props("status")}
                    data={statuses as []}
                    label={t("status")} 
                  />
                  <TextField {...props("shipmentId")} id="shipment-shipmentId">{t("shipmentId")}</TextField>
                  <div className="w-full md:w-fit xl:w-full">
                    {!showInterval ?
                    <Grid>
                      <TextField {...props("dispDate")} _type="date">{t("dispDate")}</TextField>
                    </Grid>
                      :
                      <>
                        <Grid>
                          <TextField {...props("dispDateFrom")} _type="date">{t("dispDateFrom")}</TextField>
                          <TextField {...props("dispDateTo")} _type="date">{t("dispDateTo")}</TextField>
                        </Grid>
                      </>
                    }
                  </div> 
                </Grid>
         
                <Switch size='mini' onChange={() => setShowInterval(!showInterval)} checked={showInterval} label={t("showInterval")} />
                
                <Button className='w-full md:w-auto'>{t("search")}</Button>
                <Button _type='button' className='w-full md:w-auto' onClick={() => { setSearchFields({}) }}>{t("clearsearch")}</Button>
              </form>
            </Group>
            <div className='w-full flex justify-end'>
              <Button onClick={endOfDay} variant="default" disabled={!shipments}>{t("endOfDay")}</Button>
              <Button onClick={() => setNewShipmentForm(true)} variant="add">{t("createNewShipment")}</Button>
            </div>
            <>
              {shipments && shipments.length > 0 && false &&
                <div className="schipt-table-body">
                  <table>
                    <thead>
                      <tr>
                        {/* <th></th> */}
                        <th><input type="checkbox" style={{width:"1.5em", height:"1.5em"}}
                          onChange={(e) => {
                            let checked = e.currentTarget.checked
                            let newShipments:ShipmentType[] = deepCopyOf(shipments)
                            newShipments.map(s => s.checked = checked)
                            setShipments(newShipments)
                          }}
                        /></th>
                        <th>{t("orderNumber")}</th>
                        <th>{t("receiver")}</th>
                        <th>{t("dispDate")}</th>
                        <th>{t("carrier")}</th>
                        <th>{t("status")}</th>
                        <th></th>
                        <th>{t("noteInternal")}</th>
                        <th>{t("shipmentId")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {shipments.map((s, i) => {
                        // console.log("WTF??s", s)
                        return (
                          <tr key={i} onDoubleClick={() => navigate(`/Shipment/${shipments[i].shipmentId}`)}>
                            {/* <td onClick={() => navigate(`/Shipment/${shipments[i].shipmentId}`)}><FaMagnifyingGlass className='text-schipt-black dark:text-schipt-white' /></td> */}
                            <td><input type="checkbox" checked={s.checked ?? false} style={{width:"2em", height:"2em"}}
                              onChange={() => setProp(!s.checked, i, "checked")}
                            /></td>
                            <td>{s.orderNumber}</td>                            
                            <td>
                              <div>{s.addresses.receiver.name}</div>
                              <div>{s.addresses.receiver.street1}</div>
                              <div>{s.addresses.receiver.countryCode + '-' + s.addresses.receiver.zipCode + ' ' + s.addresses.receiver.city}</div>
                            </td>
                            <td>{s.dispDate ? format(new Date(s?.dispDate), 'yyyy-MM-dd') : ""}</td>
                            <td>{s.carrier}</td>
                            
                            <td className={`status flex content-center mt-4 ${s?.status == 10 ? 'bg-gray-200 text-gray-600' : s?.status == 20 ? 'bg-yellow-200 text-yellow-600' : s?.status == 50 ? 'bg-blue-200 text-blue-600' : s?.status == 70 ? 'bg-gray-400 text-gray-700' : s?.status == 80 ? 'bg-green-200 text-green-600' : s?.status == 90 ? 'bg-orange-200 text-orange-600' : 'bg-gray-200 text-gray-600'}`}>{t(`ta_statuses.${s?.status }`)}</td>                            
                            
                            <td>{s.trackingLink &&
                              <Button className="schipt-btn secondary" onClick={() => {
                                s.trackingLink && window.open(s.trackingLink, 'targetWindow',
                                  `scrollbars=yes,
                                    resizable=yes,
                                    width=500,
                                    left=500,
                                    height=500`)
                              }} _type="button" variant='submit'>Tracking</Button>
                            }
                            </td>
                            <td>{s.noteInternal}</td>
                            <td>{s.shipmentId}</td>
                          </tr>)
                      })
                      }
                    </tbody>
                  </table>
                </div>
              }
              <DataTable 
                fetchData={fetchShipments}
                pageSize={50}
                hideColumns={["shipmentId"]}
                style={{maxHeight:"30rem"}} 
                rerender={triggerReload}
                openCallback={async (i:number, s:ShipmentType) => {
                  console.log({s})
                  navigate(`/Shipment/${s.shipmentId}`)
                }}
              />
            </>
          </Container>
        </Centralizer>
      </>
        :
        <>
          <Button variant='delete' className='absolute z-10 schipt-btn secondary' onClick={() => setNewShipmentForm(false)}>Go Back</Button>
          <Shipment />
        </>
      }
      <ConfirmDialog options={confirmDialog as ConfirmDialogProps} />
      <TourStepper 
        hide={hideTour === "1"}
        onEnd={() => {
          localStorage.setItem("hideTour", "1")
          setHideTour("1")
        }}
        tourSteps={[
        {id:"navbar-shipments", header:t("shipments"), text:t("tour.shipments"),
          onNext: async () => {
            document.querySelector("li:has(#navbar-settings)")?.classList.add("active")
            return 20
          }, 
        },
        {id:"navbar-carriers", header:t("carriers"),text:t("tour.carriers"), orientation:"left",
          onBack: () => {document.querySelector("li:has(#navbar-settings)")?.classList.remove("active")}
        },
        {id:"navbar-deliverytypes", header:t("deliveryTypes"), orientation:"left", text:t("tour.deliveryTypes"),
          onNext: () => {
            document.querySelector("li:has(#navbar-profile)")?.classList.add("active")
            document.querySelector("li:has(#navbar-settings)")?.classList.remove("active")
            return 20
          }
        },
        {id:"navbar-profile", header:t("account.profile"), orientation:"left",text:t("tour.profile"),
          onBack: () => {
            document.querySelector("li:has(#navbar-settings)")?.classList.add("active")
            document.querySelector("li:has(#navbar-profile)")?.classList.remove("active")
            return 20
          }
        },
        {id:"navbar-subsriptions", header:t("account.subscription"), orientation:"left",text:t("tour.subscriptions")},
        {id:"navbar-users", header:t("account.userAdministration"), orientation:"left",text:t("tour.users"),
          onNext: () => {document.querySelector("li:has(#navbar-profile)")?.classList.remove("active")}
        },
      ]}/>
    </>
  );
}